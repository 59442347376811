import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
//import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/horus/horus_01.jpg";
import image2 from "assets/img/cases/horus/horus_02.jpg";
import image3 from "assets/img/cases/horus/horus_03.jpg";
import image4 from "assets/img/cases/horus/horus_04.jpg";
import image5 from "assets/img/cases/horus/horus_05.jpg";
import image6 from "assets/img/cases/horus/horus_06.jpg";
import image7 from "assets/img/cases/horus/horus_07.jpg";

import Nokia from "assets/img/logos/nokia-480x480.png";

class HorusSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Automatic Inventory in Manufacturing</h2>
              <h4>Detecting and counting module racks - private 5G network practical use case</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                {/*
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                */}
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image7} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          The assembly process of a mobile phone network base station product involves
                          multiple phases. After completing one assembly phase, the PCBs are stored inside
                          module racks into a specific buffer area where they wait for the next phase.
                          Optimizing the throughput of the factory requires real-time knowledge of
                          the amount of modules in each phase. For example, to prevent bottle-necks in
                          production, a buffer area should never be completely full nor empty.
                        </p>
                        <p>
                          In 2019, Nokia approached Finwe and asked if we could develop a real-time
                          buffer area inventory solution based on video analytics. The goal was to detect
                          the amount of module racks in the buffer area and provide this information on
                          a big screen on the factory floor as well as in cell phones. The tricky part was
                          that racks could not be marked, there were multiple types of racks, and they
                          were frequently piled on top of each other. The buffer area also contained
                          modules for different products and we needed to track them separately with
                          a minimal number of cameras.
                        </p>
                        <p>
                          We familiarized ourselves with the buffer area and racks and created a virtual
                          model of them to find the optimal positions for the cameras, the minimum number of
                          cameras, and required field-of-view for the lenses. We then purchased the cameras,
                          designed a custom rig, and 3D-printed them in-house. A video logger was installed
                          and several weeks worth of data was collected for algorithm development.
                        </p>
                        <p>
                          By comparing a few alternatives we found a suitable algorithm and implemented first
                          version for testing purposes. Also a web-based user interface was developed, to
                          be shown on a 65" touch screen TV on the factory floor as well as on 5G phones.
                          In co-operation with Nokia, private 5G network was used for transmitting 4x
                          high-quality video streams to the algorithm and another 4x streams for the user
                          interface. The capacity of the 5G network provided us more than enough bandwidth.
                          Finally, the algorithm was performance tested and further optimized.
                        </p>
                        <p>
                          This project was perfect for us as it allowed to combine so many of our skills:
                          3D simulations, knowledge of various camera types, 3D object design and printing,
                          video capture, algorithm development, as well as designing and creating compelling
                          user interfaces. Despite of long data collection period, the first version was
                          live only 2.5 months after project kick-off.
                        </p>
                        <p>
                          <b>
                            To learn how video analytics can be used in your company, contact us and book an
                            e-meeting. Our offering includes on-site consultation for analysing potential
                            applications in co-operation with the customer.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h5>Customer:</h5>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Nokia}
                              alt="Nokia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(HorusSection);
